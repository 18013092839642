import React from 'react';
import styled from 'styled-components';
import { H3, InputLabel } from '@increasecard/typed-components';
import { Container as BaseContainer } from '../../components/Container';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import { addHours } from 'date-fns';
import { BackgroundBase } from '../../backgrounds/BackgroundBase';
import { RoundedSquare } from '../../backgrounds/shapes/RoundedSquare';
import { MEDIUM } from '../../breakpoints';
import { SuperHeader } from '../../components/Typography';

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colorsNew.gray.white};
  padding: 80px 0;

  @media (min-width: ${MEDIUM}) {
    padding: 148px 0;
  }
`;

const Container = styled(BaseContainer)`
  display: flex;
  position: relative;
  flex-direction: column;

  @media (min-width: ${MEDIUM}) {
    flex-direction: row-reverse;
  }
`;

const Title = styled(SuperHeader)`
  color: ${({ theme }) => theme.colorsNew.increase.accent};
  min-width: max-content;
`;

const Events = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;

  @media (min-width: ${MEDIUM}) {
    margin-top: 0;
    height: 227px;
    flex-direction: row;
  }
`;
const EventText = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 127px;
  @media (min-width: ${MEDIUM}) {
    width: auto;
    flex-direction: column;
  }
`;
const EventName = styled(H3)`
  line-height: 19px;
`;
const EventDate = styled(InputLabel)``;
const Arrow = styled.div`
  position: relative;
  width: 37.5px;
  ::after {
    content: '';
    height: 14px;
    width: 14px;
    background: ${({ theme }) => theme.colorsNew.increase.accent};
    border-radius: 50%;
  }

  @media (min-width: ${MEDIUM}) {
    width: 100%;
    height: 48px;
  }
`;
const Event = styled.li`
  display: flex;
  width: 50%;
  justify-content: flex-end;

  :nth-child(2n) {
    ${EventText} {
      text-align: right;
      padding-right: 7px;
      border-right: 1px solid ${({ theme }) => theme.colorsNew.increase.accent};
    }

    ${Arrow} {
      margin-top: 8px;
      border-top: 1px solid ${({ theme }) => theme.colorsNew.increase.accent};

      ::after {
        position: absolute;
        right: -7px;
        top: -7px;
      }
    }
  }
  :nth-child(2n - 1) {
    flex-direction: row-reverse;
    align-self: flex-end;

    ${EventText} {
      padding-left: 7px;
      border-left: 1px solid ${({ theme }) => theme.colorsNew.increase.accent};
    }

    ${Arrow} {
      margin-top: 8px;
      border-top: 1px solid ${({ theme }) => theme.colorsNew.increase.accent};

      ::after {
        position: absolute;
        left: -7px;
        top: -7px;
      }
    }
  }

  @media (min-width: ${MEDIUM}) {
    flex-direction: column;
    justify-content: flex-start;
    height: 112px;
    width: auto;

    :nth-child(2n) {
      ${EventText} {
        text-align: left;
        padding: 0;
        border: 0;
      }
      ${EventName} {
        margin-bottom: 8px;
      }

      ${EventDate} {
        padding-bottom: 7px;
        border-bottom: 1px solid
          ${({ theme }) => theme.colorsNew.increase.accent};
      }

      ${Arrow} {
        border-top: 0;
        margin-top: 0;
        margin-left: 8px;
        border-left: 1px solid ${({ theme }) => theme.colorsNew.increase.accent};

        ::after {
          position: absolute;
          top: unset;
          right: unset;
          bottom: -8.5px;
          left: -7px;
        }
      }
    }
    :nth-child(2n - 1) {
      flex-direction: column-reverse;

      ${EventText} {
        padding: 0;
        border: 0;
        flex-direction: column-reverse;
      }
      ${EventDate} {
        padding-top: 7px;
        border-top: 1px solid ${({ theme }) => theme.colorsNew.increase.accent};
      }

      ${EventName} {
        margin-top: 8px;
      }

      ${Arrow} {
        margin-left: 8px;
        margin-top: 0;
        border-top: 0;
        border-left: 1px solid ${({ theme }) => theme.colorsNew.increase.accent};

        ::after {
          position: absolute;
          top: -8.5px;
          left: -7px;
          bottom: unset;
          right: unset;
        }
      }
    }
  }
`;

const Timeline = styled.div`
  position: absolute;
  height: calc(100% - 16px);
  padding-top: 8px;
  top: 96px;
  bottom: 0;
  left: calc(50% - 1.5px);
  border-left: 3px solid ${({ theme }) => theme.colorsNew.increase.accent};

  @media (min-width: ${MEDIUM}) {
    border-left: 0;
    padding-left: 8px;
    border-bottom: 3px solid ${({ theme }) => theme.colorsNew.increase.accent};
    top: 112px;
    bottom: unset;
    left: unset;
    height: auto;
    padding-top: 0;
    width: calc(100% - 40px);
  }
`;

const Square = styled(RoundedSquare).attrs({
  rotation: '21deg',
  size: '220px',
  bgColor: theme => theme.colorsNew.increase.regular,
})`
  position: absolute;
  opacity: 0.5;

  top: calc(-110px / 2);
  right: calc(-110px / 2);

  @media (min-width: ${MEDIUM}) {
    top: calc(50% - 220px / 2);
    right: calc(5% + 110px);
  }
`;

export function OurHistorySection({ title, ourHistoryEvents }) {
  return (
    <BackgroundBase
      baseBackground={theme => theme.colorsNew.increase.dark}
      renderBackground={() => <Square />}
    >
      <Wrapper>
        <Container>
          <Timeline />
          <Title>{title}</Title>
          <Events>
            {ourHistoryEvents.map(({ name, date }) => (
              <Event key={name}>
                <EventText>
                  <EventName dangerouslySetInnerHTML={{ __html: name }} />
                  <EventDate>
                    /{' '}
                    {format(addHours(Date.parse(date), 3), 'MMM yyyy', {
                      locale: es,
                    })}
                  </EventDate>
                </EventText>
                <Arrow />
              </Event>
            ))}
          </Events>
        </Container>
      </Wrapper>
    </BackgroundBase>
  );
}
