import styled, { css } from 'styled-components';
import { MEDIUM } from '../breakpoints';

export const DisplayTitle = styled.h1`
  font-style: normal;
  font-weight: bold;
  ${({ underline }) => underline && `text-decoration: underline;`}
  margin-bottom: 24px;
  ${({ titleType }) =>
    titleType === 'longText'
      ? css`
          font-size: 42px;
          line-height: 51px;
        `
      : css`
          font-size: 56px;
          line-height: 68px;
        `};
  letter-spacing: -0.04em;

  @media (min-width: ${MEDIUM}) {
    font-size: 70px;
    line-height: 85px;
    letter-spacing: 0;
  }
`;
