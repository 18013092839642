import {
  H1 as H1Base,
  SuperHeader as SuperHeaderBase,
} from '@increasecard/typed-components';
import styled from 'styled-components';

export const SuperHeader = styled(SuperHeaderBase)`
  line-height: 51px;
`;

export const H1 = styled(H1Base)`
  line-height: 40px;
`;
