import React from 'react';
import { Container as BaseContainer } from '../../components/Container';
import { H3 } from '@increasecard/typed-components';
import { Grid as BaseGrid } from '../../components/Grid';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import { SMALL, MEDIUM } from '../../breakpoints';
import { BackgroundBase } from '../../backgrounds/BackgroundBase';
import { RoundedSquare } from '../../backgrounds/shapes/RoundedSquare';

const Container = styled(BaseContainer)`
  padding-top: 56px;
  padding-bottom: 56px;
  position: relative;
`;

const Grid = styled(BaseGrid)`
  align-items: center;

  @media (min-width: ${SMALL}) {
    grid-auto-flow: column dense;
  }
`;

const ImageWrapper = styled.div`
  grid-column: span 2 / span 2;
  margin: 32px 64px 0;
  @media (min-width: ${SMALL}) {
    margin: 0;
    grid-column: 2 / span 2;
  }
`;
const TextWrapper = styled.div`
  grid-column: span 2 / span 2;
  @media (min-width: ${SMALL}) {
    grid-column: 5 / span 4;
  }
`;
const Title = styled.h1`
  ${({ theme }) => theme.typography.H1}
  background: ${({ theme }) => theme.colorsNew.gray.white};
  font-weight: bold;
  line-height: 40px;
  margin-bottom: 16px;

  @media (min-width: ${MEDIUM}) {
    ${({ theme }) => theme.typography.superHeader}
    line-height: 51px;
  }
`;

const GreenBar = styled.div`
  display: none;
  position: absolute;
  left: 0;
  height: calc(100% - 112px);
  top: 56px;
  width: 140px;
  background: ${({ theme }) => theme.colorsNew.increase.light30};

  @media (min-width: ${SMALL}) {
    display: block;
  }
`;

const SquareOutline = styled(RoundedSquare).attrs({
  size: '220px',
  rotation: '21deg',
})`
  border: 1.5px solid ${({ theme }) => theme.colorsNew.increase.regular};
  position: absolute;
  top: calc(140px - 110px);
  right: calc(64px - 110px);

  @media (min-width: ${SMALL}) {
    right: calc(18% - 110px);
    top: calc(32% - 110px);
  }
`;

export function SistemaBSection({ title, description, image }) {
  return (
    <BackgroundBase
      baseBackground={theme => theme.colorsNew.gray.white}
      renderBackground={() => (
        <>
          <GreenBar />
          <SquareOutline />
        </>
      )}
    >
      <Container>
        <Grid>
          <TextWrapper>
            <Title>{title}</Title>
            <H3 weight="normal">{description}</H3>
          </TextWrapper>
          <ImageWrapper>
            <GatsbyImage {...image.imageSharp.childImageSharp} />
          </ImageWrapper>
        </Grid>
      </Container>
    </BackgroundBase>
  );
}
