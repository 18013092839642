import React from 'react';
import styled from 'styled-components';
import { H3 } from '@increasecard/typed-components';
import { CTAButton } from '../components/CTAButton';
import { Container } from '../components/Container';
import { MEDIUM } from '../breakpoints';
import { DisplayTitle } from '../components/DisplayTitle';
import { IntegrationsHeroBackground } from '../backgrounds/IntegrationsHeroBackground';
import { NosotrosHeroBackground } from '../backgrounds/NosotrosHeroBackground';

const HeroContainer = styled(Container)`
  min-height: ${({ size }) => (size === 'small' ? 'unset' : '600px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 36px;
  @media (min-width: ${MEDIUM}) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  text-align: center;
`;

const Title = styled(DisplayTitle)`
  color: ${({ theme, variant }) =>
    variant === 'enterprise'
      ? theme.colorsNew.increase.accent
      : theme.colorsNew.increase.regular};
  max-width: 13em;
`;

function getBackground(backgroundVariant) {
  switch (backgroundVariant) {
    case 'integrations':
      return IntegrationsHeroBackground;
    default:
      return NosotrosHeroBackground;
  }
}

export function OnlyTextHeroSection({
  title,
  subtitle,
  size,
  cta,
  backgroundVariant,
  variant,
}) {
  const Background = getBackground(backgroundVariant);
  return (
    <Background>
      <HeroContainer size={size} variant={variant}>
        <Title
          dangerouslySetInnerHTML={{ __html: title }}
          titleType="longText"
          variant={variant}
        />
        <H3
          dangerouslySetInnerHTML={{ __html: subtitle }}
          weight="normal"
          style={{
            marginBottom: '48px',
            maxWidth: '36em',
            margin: '16px auto 32px',
          }}
        />
        {cta && <CTAButton {...cta} type="accent" />}
      </HeroContainer>
    </Background>
  );
}
