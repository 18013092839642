import React from 'react';
import { Grid as BaseGrid } from '../../components/Grid';
import { Container } from '../../components/Container';
import styled from 'styled-components';
import { H3 } from '@increasecard/typed-components';
import { MEDIUM } from '../../breakpoints';
import GatsbyImage from 'gatsby-image';
import { H1 } from '../../components/Typography';

const Description = styled.div``;
const DescriptionParagraph = styled(H3).attrs({ weight: 'normal' })`
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const TextWrapper = styled.div`
  position: relative;
`;

const Title = styled(H1)`
  margin-bottom: 16px;
`;

const BackgroundText = styled.div`
  font-size: 64px;
  line-height: 88px;
  letter-spacing: -0.03em;
  top: -40px;
  opacity: 0.05;
  position: absolute;
  text-transform: uppercase;
  font-weight: bold;
  width: 100%;
  text-align: center;

  @media (min-width: ${MEDIUM}) {
    width: unset;
    text-align: left;
    top: -160px;
    letter-spacing: -0.011em;
    font-size: 180px;
    line-height: 218px;
  }
`;

const ImageWrapper = styled.div``;

const Grid = styled(BaseGrid)`
  position: relative;
  padding: 80px 0;
  min-height: 600px;
  align-items: center;

  @media (min-width: ${MEDIUM}) {
    grid-auto-flow: column dense;
    ${BackgroundText} {
      left: -80px;
    }

    ${ImageWrapper} {
      grid-column-start: 6;
    }
    ${TextWrapper} {
      grid-column-start: 2;
    }
  }
`;

export function NosotrosPhilosophySection({
  title,
  description,
  image,
  backgroundText,
}) {
  return (
    <Container>
      <Grid>
        <ImageWrapper className="grid-span-md-4 grid-span-sm-2">
          {image && <GatsbyImage {...image.imageSharp.childImageSharp} />}
        </ImageWrapper>
        <TextWrapper className="grid-span-md-3 grid-span-sm-2">
          <BackgroundText>{backgroundText}</BackgroundText>
          <Title>{title}</Title>
          <Description>
            {description.split('\n\n').map(paragraph => (
              <DescriptionParagraph>{paragraph}</DescriptionParagraph>
            ))}
          </Description>
        </TextWrapper>
      </Grid>
    </Container>
  );
}
