import React from 'react';
import { H3 } from '@increasecard/typed-components';
import styled from 'styled-components';
import { Container } from '../../components/Container';
import { Grid } from '../../components/Grid';
import { RoundedSquare } from '../../backgrounds/shapes/RoundedSquare';
import GatsbyImage from 'gatsby-image';
import { MEDIUM } from '../../breakpoints';
import { H1 } from '../../components/Typography';

const Description = styled.div``;
const DescriptionParagraph = styled(H3).attrs({ weight: 'normal' })`
  :not(:last-child) {
    margin-bottom: 8px;
  }

  ul {
    ${({ theme }) => theme.typography.H3}
    line-height: 19px;
    margin-top: 40px;
    font-weight: bold;
    color: ${({ theme }) => theme.colorsNew.increase.regular};
    display: grid;
    align-items: end;
    grid-gap: 16px;
    grid-template-columns: repeat(2, 122px);
    justify-content: center;

    @media (min-width: ${MEDIUM}) {
      margin-top: 24px;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  li {
    padding: 12px 0;
    border-bottom: 1.5px solid
      ${({ theme }) => theme.colorsNew.increase.regular};
  }
`;

const TextWrapper = styled.div`
  position: relative;
`;

const Title = styled(H1)`
  margin-bottom: 16px;
`;

const BackgroundText = styled.div`
  font-size: 64px;
  line-height: 88px;
  letter-spacing: -0.03em;
  top: -40px;
  opacity: 0.05;
  position: absolute;
  text-transform: uppercase;
  font-weight: bold;
  width: 100%;
  text-align: center;

  @media (min-width: ${MEDIUM}) {
    width: unset;
    text-align: left;
    top: -160px;
    letter-spacing: -0.011em;
    font-size: 180px;
    line-height: 218px;
  }
`;

const SmallGreenSquare = styled(RoundedSquare).attrs({
  size: { sm: '16px', md: '28px' },
  rotation: '-60deg',
  bgColor: theme => theme.colorsNew.increase.light50,
})`
  position: absolute;
  top: 240px;
  @media (min-width: ${MEDIUM}) {
    top: unset;
    bottom: 40%;
  }
`;

const ImageWrapper = styled.div``;
const ValueWrapper = styled(Grid)`
  position: relative;
  padding: 80px 0;
  min-height: 600px;
  align-items: center;

  :nth-child(2n) {
    ${SmallGreenSquare} {
      left: 48px;
    }
  }

  :nth-child(2n - 1) {
    ${SmallGreenSquare} {
      right: 48px;
    }
  }

  @media (min-width: ${MEDIUM}) {
    grid-auto-flow: column dense;

    :nth-child(3n - 2) {
      ${BackgroundText} {
        left: 0;
      }

      ${SmallGreenSquare} {
        right: 80px;
      }

      ${ImageWrapper} {
        grid-column-start: 6;
      }
      ${TextWrapper} {
        grid-column-start: 1;
      }
    }

    :nth-child(3n - 1) {
      ${BackgroundText} {
        left: unset;
        right: 0;
      }
      ${ImageWrapper} {
        grid-column-start: 1;
      }
      ${TextWrapper} {
        grid-column-start: 6;
      }

      ${SmallGreenSquare} {
        left: 80px;
      }
    }
    :nth-child(3n) {
      ${BackgroundText} {
        left: -104px;
      }
      ${TextWrapper} {
        grid-column-start: 2;
      }
    }
  }
`;

export function NosotrosValuesSection({ nosotrosValues }) {
  return (
    <Container>
      {nosotrosValues.map(({ title, description, image, backgroundText }) => (
        <ValueWrapper>
          <SmallGreenSquare />
          <ImageWrapper className="grid-span-md-4 grid-span-sm-2">
            {image && <GatsbyImage {...image.imageSharp.childImageSharp} />}
          </ImageWrapper>
          <TextWrapper className="grid-span-md-3 grid-span-sm-2" key={title}>
            <BackgroundText>{backgroundText}</BackgroundText>
            <Title>{title}</Title>
            <Description>
              {description.split('\n\n').map(paragraph => (
                <DescriptionParagraph
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                />
              ))}
            </Description>
          </TextWrapper>
        </ValueWrapper>
      ))}
    </Container>
  );
}
