import React from 'react';
import { BackgroundBase } from './BackgroundBase';
import { RoundedSquare } from './shapes/RoundedSquare';
import styled from 'styled-components';
import { MEDIUM } from '../breakpoints';

const SquareOutline = styled(RoundedSquare).attrs({
  size: '304px',
  rotation: '-30deg',
})`
  position: absolute;
  bottom: calc(-136px - 304px / 2);
  right: calc(40px - 304px / 2);
  border: 3px solid ${({ theme }) => theme.colorsNew.gray.white};

  @media (min-width: ${MEDIUM}) {
    bottom: calc(80px - 304px / 2);
    right: calc(24px - 304px / 2);
  }
`;

const SmallSquare = styled(RoundedSquare).attrs({
  size: '16px',
  rotation: '-30deg',
  bgColor: theme => theme.colorsNew.increase.accent,
})`
  position: absolute;
  top: calc(80px - 16px / 2);
  left: calc(15% - 16px / 2);

  @media (min-width: ${MEDIUM}) {
    top: calc(50% - 16px / 2);
    left: calc(15% - 16px / 2);
  }
`;

export function IntegrationsHeroBackground({ children }) {
  return (
    <BackgroundBase
      baseBackground={theme => theme.colorsNew.gray.black}
      renderBackground={() => (
        <>
          <SquareOutline />
          <SmallSquare />
        </>
      )}
    >
      {children}
    </BackgroundBase>
  );
}
