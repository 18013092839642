import React from 'react';
import { Container } from '../../components/Container';
import styled from 'styled-components';
import { CTAButton } from '../../components/CTAButton';
import { SuperHeader } from '../../components/Typography';

const Wrapper = styled.div`
  text-align: center;
  background: ${({ theme }) => theme.colorsNew.increase.dark};
  padding: 32px 0;
`;

const Title = styled(SuperHeader)`
  color: ${({ theme }) => theme.colorsNew.increase.accent};
  margin-bottom: 8px;
`;

export function CareersCtaSection({ title, cta }) {
  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        <CTAButton {...cta} type="accent" />
      </Container>
    </Wrapper>
  );
}
