import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { Section } from '../components/Section';
import { OnlyTextHeroSection } from '../pageSections/OnlyTextHeroSection';
import { NosotrosValuesSection } from '../pageSections/nosotros/NosotrosValuesSection';
import { SistemaBSection } from '../pageSections/nosotros/SistemaBSection';
import { OurHistorySection } from '../pageSections/nosotros/OurHistorySection';
import { NosotrosPhilosophySection } from '../pageSections/nosotros/NosotrosPhilosophySection';
import { CareersCtaSection } from '../pageSections/nosotros/CareersCtaSection';
import { Layout } from '../layout/Layout';

const sectionMapping = {
  Strapi_ComponentPageSectionsOnlyTextHeroSection: OnlyTextHeroSection,
  Strapi_ComponentPageSectionsNosotrosValuesSection: NosotrosValuesSection,
  Strapi_ComponentPageSectionsSistemaBsEction: SistemaBSection,
  Strapi_ComponentPageSectionsOurHistorySection: OurHistorySection,
  Strapi_ComponentPageSectionsNosotrosPhilosophySection: NosotrosPhilosophySection,
  Strapi_ComponentPageSectionsCarreersCtaSection: CareersCtaSection,
};

export default ({ data }) => {
  const { seoInfo, sections } = data.strapi.nosotrosPage;
  return (
    <Layout>
      <SEO
        title={seoInfo.pageTitle}
        description={seoInfo.pageDescription}
        keywords={seoInfo.keywords}
      />
      {sections.map(s => {
        return (
          <Section mapping={sectionMapping} key={`${s.type}${s.id}`} {...s} />
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  query {
    strapi {
      nosotrosPage {
        seoInfo {
          pageDescription
          pageTitle
          keywords
        }

        sections {
          type: __typename
          ... on Strapi_ComponentPageSectionsOnlyTextHeroSection {
            id
            title
            subtitle
            cta {
              ...CTA
            }
          }

          ... on Strapi_ComponentPageSectionsNosotrosValuesSection {
            id
            nosotrosValues {
              title
              description
              backgroundText
              image {
                ...Image
              }
            }
          }

          ... on Strapi_ComponentPageSectionsSistemaBsEction {
            title
            description
            image {
              ...Image
            }
          }

          ... on Strapi_ComponentPageSectionsOurHistorySection {
            id
            ourHistoryEvents {
              id
              name
              date
            }
            title
          }

          ... on Strapi_ComponentPageSectionsNosotrosPhilosophySection {
            id
            title
            description
            backgroundText
            image {
              ...Image
            }
          }

          ... on Strapi_ComponentPageSectionsCarreersCtaSection {
            id
            title
            cta {
              text
              href
            }
          }
        }
      }
    }
  }
`;
