import React from 'react';
import { BackgroundBase } from './BackgroundBase';
import { RoundedSquare } from './shapes/RoundedSquare';
import styled from 'styled-components';
import { MEDIUM } from '../breakpoints';

const SmallSquare = styled(RoundedSquare).attrs({
  size: '25px',
  rotation: '-30deg',
  bgColor: theme => theme.colorsNew.increase.light70,
})`
  position: absolute;
  top: calc(40px - 16px / 2);
  right: calc(15% - 16px / 2);

  @media (min-width: ${MEDIUM}) {
    top: calc(10% - 16px / 2);
    right: calc(15% - 16px / 2);
  }
`;

export function NosotrosHeroBackground({ children }) {
  return (
    <BackgroundBase
      baseBackground={theme => theme.colorsNew.gray.white}
      renderBackground={() => (
        <>
          <SmallSquare />
        </>
      )}
    >
      {children}
    </BackgroundBase>
  );
}
